<template>
    <div class="surface-0 overflow-hidden">
        <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
            <router-link to="/" class="flex align-items-center">
                <img :src="'layout/images/logo.png'" alt="Contablus Logo" height="50" class="mr-0 lg:mr-2">
            </router-link>
            <a class="cursor-pointer block lg:hidden text-700 p-ripple" v-ripple
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                    <li>
                        <a @click="smoothScroll('#hero')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span style="color: #27285c;">Inicio</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#features')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span style="color: #27285c;">Funciones</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#platform')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span style="color: #27285c;">Plataforma</span>
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#pricing')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <span style="color: #27285c;">Precios</span>
                        </a>
                    </li>
                </ul>
                <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                    <Button label="Iniciar sesión" class="p-button-text p-button-rounded border-none font-light line-height-2" style="color: #f18700;" @click="onLogin"></Button>
                    <Button label="Registrarse" class="p-button-rounded border-none ml-5 font-light text-white line-height-2" style="background-color: #f18700;"></Button>
                </div>
            </div>
        </div>

        <div class="grid py-4 px-4 lg:px-8 relative" id="hero">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2"><span class="font-light block">Controle su negocio</span>de donde sea</h1>
                <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">Desde facturación electrónica hasta reportes, aquí obtendra todas las herramientas para administrar su negocio.</p>
                <Button label="Comenzar" class="p-button-rounded text-xl border-none mt-5 font-normal text-white line-height-3 px-3" style="background-color: #f18700;"></Button>
            </div>
            <img src="layout/images/screen.png" class="bottom-0" alt="hero screen" style="right:10%;">
        </div>
        
        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-8 mb-4">
                    <h2 class="text-900 font-normal mb-2">Contablus le ofrece</h2>
                    <span class="text-600 text-2xl">una variedad de funcionalidades para agilitar el proceso administrativo de su negocio</span>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <img src="layout/images/iconos-landingpage/1-10.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Acceso Remoto</h5>
                            <span class="text-600">Administre su negocio de donde sea.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-11.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Información Respaldada</h5>
                            <span class="text-600">Su información yace segura en la nube.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-indigo-200" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-map text-2xl text-indigo-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-12.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Simpleza</h5>
                            <span class="text-600">Interfaz comprensible y fácil de usar.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-13.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Facturación 24/7</h5>
                            <span class="text-600">Emita documentos electrónicos a cualquier hora.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-star text-2xl text-orange-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-14.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Reportes</h5>
                            <span class="text-600">Vea reportes cruciales con información tiempo real.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-pink-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-moon text-2xl text-pink-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-15.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Descargue información</h5>
                            <span class="text-600">Descargue sus los datos de sus clientes, productos, ventas y más.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-teal-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-shopping-cart text-2xl text-teal-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-16.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">CRM</h5>
                            <span class="text-600">Analice sus clientes para la toma de decisiones estratégicas.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-blue-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-globe text-2xl text-blue-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-17.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Carga Masiva</h5>
                            <span class="text-600">Cargue su catalogo de productos de forma masiva.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background-color: #f7ab47;">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <!-- <div class="flex align-items-center justify-content-center bg-purple-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                            </div> -->
                            <img src="layout/images/iconos-landingpage/1-18.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                            <h5 class="mb-2 text-900">Panel de control</h5>
                            <span class="text-600">Vea de manera macro el comportamiento de su negocio.</span>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 mt-8 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                        <h3 class="text-gray-900 mb-2">Joséphine Miller</h3>
                        <span class="text-gray-600 text-2xl">Peak Interactive</span>
                        <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”</p>
                        <img src="layout/images/peak-logo.svg" class="mt-4" alt="">

                    </div>
                </div> -->
            </div>
        </div>
            
        <div class="py-4 px-4 lg:px-8 mx-0 my-6 lg:mx-8" id="platform">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Nuestra plataforma</h2>
                <span class="text-600 text-2xl">Adaptable para smartphones y computadoras</span>
            </div>

            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0" style="border-radius:20px;">
                    <img src="layout/images/mockup.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <!-- <div class="flex align-items-center justify-content-center bg-purple-200" style="width:4.2rem; height:4.2rem; border-radius: 10px;">
                        <i class="pi pi-fw pi-mobile text-5xl text-purple-700"></i>
                    </div> -->
                    <img src="layout/images/iconos-landingpage/1-13.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                    <h2 class="line-height-1 text-4xl font-normal" style="color: #27285c;">Facturación 24/7</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">Emita variedad de documentos electrónicos de manera inmediata.</span>
                </div>
            </div>

            <div class="grid my-8 pt-2 md:pt-8">
                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start align-items-center">
                    <!-- <div class="flex align-items-center justify-content-center bg-yellow-200" style="width:4.2rem; height:4.2rem; border-radius:10px;">
                        <i class="pi pi-fw pi-desktop text-5xl text-yellow-700"></i>
                    </div> -->
                    <img src="layout/images/iconos-landingpage/1-18.png" class="bottom-0" alt="icon-1" style="width: 3.5rem;">
                    <h2 class="line-height-1 text-4xl font-normal" style="color: #27285c;">Administre su negocio</h2>
                    <span class="text-700 text-2xl line-height-3 mr-0 md:mr-2" style="max-width:650px;">Accesa a los datos de sus ventas, gastos, clientes y más.</span>
                </div>

                <div class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0" style="border-radius:8px;">
                    <img src="layout/images/mockup-desktop.png" class="w-11 pt-4" alt="mockup">
                </div>
            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="pricing">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Conozca nuestros precios</h2>
                <span class="text-600 text-2xl">Elija el que se acomode a sus necesidades</span>
            </div>

            <div class="grid justify-content-between mt-8 md:mt-0">
                <div class="col-12 lg:col-4 p-0 md:p-3">
                    <div class="p-3 flex flex-column pricing-hover" style="border:2px solid; border-radius:10px;">
                        <!-- <h3 class="text-900 text-center">Emprendedor</h3> -->
                        <img src="layout/images/pricing/1-26.png" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <!-- <span class="text-5xl font-bold mr-2 text-900">$24,99</span>
                            <span class="text-600">por año</span> -->
                            <Button label="Solicitar" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2" style="background-color: #f18700; width: 10rem;"></button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">1 Actividad Económica</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">20 registros de clientes</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">20 registros de productos/servicios</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">100 Doc. Electrónicos</span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-12 lg:col-4 p-0 md:p-3 mt-4 md:mt-0">
                    <div class="p-3 flex flex-column pricing-hover" style="border:2px solid; border-radius:10px;">
                        <!-- <h3 class="text-900 text-center">Profesional</h3> -->
                        <img src="layout/images/pricing/1-27.png" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <!-- <span class="text-5xl font-bold mr-2 text-900">$99,99</span>
                            <span class="text-600">por año</span> -->
                            <Button label="Solicitar" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2" style="background-color: #f18700; width: 10rem;"></button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">2 Actividades Económicas</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">50 registros de clientes</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">50 registros de productos/servicios</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">350 Doc. Electrónicos</span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-12 lg:col-4 p-0 md:p-3 mt-4 md:mt-0">
                    <div class="p-3 flex flex-column pricing-hover" style="border:2px solid; border-radius:10px;">
                        <!-- <h3 class="text-900 text-center">Empresarial</h3> -->
                        <img src="layout/images/pricing/1-28.png" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <!-- <span class="text-5xl font-bold mr-2 text-900">$149,99</span>
                            <span class="text-600">por año</span> -->
                            <Button label="Solicitar" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2" style="background-color: #f18700; width: 10rem;"></button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">3 Actividades Económicas</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">125 registros de clientes</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">125 registros de productos/servicios</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">750 Doc. Electrónicos</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-0 md:p-3 mt-4 md:mt-0">
                    <div class="p-3 flex flex-column pricing-hover" style="border:2px solid; border-radius:10px;">
                        <!-- <h3 class="text-900 text-center">Empresarial</h3> -->
                        <img src="layout/images/pricing/1-29.png" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <!-- <span class="text-5xl font-bold mr-2 text-900">$149,99</span>
                            <span class="text-600">por año</span> -->
                            <Button label="Solicitar" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2" style="background-color: #f18700; width: 10rem;"></button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Actividades Económicas ilimitadas</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Ilimitado registro de clientes </span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Ilimitado registro de productos/servicios</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Doc. Electrónicos ilimitados</span>
                            </li>
                        </ul>
                    </div>
                </div>
        </div> 
        </div>

        <div class="py-4 px-4 mx-0 mt-8 lg:mx-8" id="contact">
            <div class="grid justify-content-between">
                <div class="col-12 md:col-2" style="margin-top:-1.5rem;">
                    <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                        <!-- <img :src="'layout/images/logo-' + logoColor + '.svg'" alt="footer sections" width="50" height="50" class="mr-2">
                        <h4 class="font-medium text-3xl text-900">SAKAI</h4> -->
                        <router-link to="/" class="flex align-items-center">
                            <img :src="'layout/images/logo.png'" alt="Contablus Logo" height="50" class="mr-0 lg:mr-2">
                        </router-link>
                    </div>
                </div>

                <div class="col-12 md:col-7">
                    <div class="grid text-center md:text-left">
                        <div class="col-12 md:col-3">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Correo:</h4>
                            <h6 class="line-height-3 text-xl block cursor-pointer mb-2" style="color: #27285c">info@contablus.com</h6>
                            <!-- <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700"></a> -->
                            <!-- <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">News</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Investor Relations</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Careers</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Media Kit</a> -->
                        </div>
                        
                        <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Teléfono:</h4>
                            <h6 class="line-height-3 text-xl block cursor-pointer mb-2" style="color: #27285c">0987218162</h6>
                            <!-- <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Learn</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Case Studies</a> -->
                        </div>
        
                        <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Ecuador</h4>
                            <h6 class="line-height-3 text-xl block cursor-pointer mb-2" style="color: #27285c">Contablus 2022 ®</h6>
                            <!-- <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Discord</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Events<img src="layout/images/new-badge.svg" class="ml-2"/></a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">FAQ</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Blog</a> -->
                        </div>
        
                        <!-- <div class="col-12 md:col-3 mt-4 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Legal</h4>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Brand Policy</a>
                            <a class="line-height-3 text-xl block cursor-pointer mb-2 text-700">Privacy Policy</a>
                            <a class="line-height-3 text-xl block cursor-pointer text-700">Terms of Service</a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        },
        onLogin() {
            this.$router.push('/login');
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style>
#hero{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #18407f 0%, #FFFFFF 100%);
    height:700px;
    overflow:hidden;
}
.pricing-hover {
    border-color: #eeedef !important;
}
.pricing-hover:hover {
    border-color: #f7ab47 !important;
}

@media screen and (min-width: 768px) {
    #hero{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px){
    #hero > img {
        position: absolute;
    }

    #hero > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #hero {
        height: 600px;
    }

    #hero > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #hero > div {
        width: 100%;
    }

    #hero > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>